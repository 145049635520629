import React from "react";

export default ({ color }) => (
  <svg width="95" height="95" viewBox="0 0 95 95" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill={color} d="M60.2 25.5004C58.3 25.5004 56.4 26.1004 54.9 27.1004L43.3 15.4004C44.3 13.9004 44.9 12.1004 44.9 10.1004C44.9 4.80039 40.6 0.400391 35.2 0.400391C29.8 0.400391 25.5 4.70039 25.5 10.1004C25.5 12.0004 26.1 13.9004 27.1 15.4004L15.4 27.0004C13.9 26.0004 12.1 25.4004 10.1 25.4004C4.80002 25.4004 0.400024 29.7004 0.400024 35.1004C0.400024 40.5004 4.70003 44.8004 10.1 44.8004C15.5 44.8004 19.8 40.5004 19.8 35.1004C19.8 33.2004 19.2 31.3004 18.2 29.8004L29.8 18.2004C31.3 19.2004 33.1 19.8004 35.1 19.8004C37 19.8004 38.9 19.2004 40.4 18.2004L52 29.8004C51 31.3004 50.4 33.1004 50.4 35.1004C50.4 40.4004 54.7 44.8004 60.1 44.8004C65.4 44.8004 69.8 40.5004 69.8 35.1004C69.8 29.7004 65.5 25.5004 60.2 25.5004Z"  />
    <path fill={color} d="M85.2 50.5004C79.9 50.5004 75.5 54.8004 75.5 60.2004C75.5 62.1004 76.1 64.0004 77.1 65.5004L65.4 77.0004C63.9 76.0004 62.1 75.4004 60.1 75.4004C58.2 75.4004 56.3 76.0004 54.8 77.0004L43.3 65.4004C44.3 63.9004 44.9 62.1004 44.9 60.1004C44.9 54.8004 40.6 50.4004 35.2 50.4004C29.8 50.4004 25.5 54.7004 25.5 60.1004C25.5 65.4004 29.8 69.8004 35.2 69.8004C37.1 69.8004 39 69.2004 40.5 68.2004L52.1 79.8004C51.1 81.3004 50.5 83.1004 50.5 85.1004C50.5 90.4004 54.8 94.8004 60.2 94.8004C65.5 94.8004 69.9 90.5004 69.9 85.1004C69.9 83.2004 69.3 81.3004 68.3 79.8004L79.9 68.2004C81.4 69.2004 83.2 69.8004 85.2 69.8004C90.5 69.8004 94.9 65.5004 94.9 60.1004C94.8 54.8004 90.5 50.5004 85.2 50.5004Z"  />
    <path fill={color} d="M35.2 75.5004C33.3 75.5004 31.4 76.1004 29.9 77.1004L18.3 65.4004C19.3 63.9004 19.9 62.1004 19.9 60.1004C19.9 58.2004 19.3 56.3004 18.3 54.8004L29.9 43.2004C31.4 44.2004 33.2 44.8004 35.2 44.8004C40.5 44.8004 44.9 40.5004 44.9 35.1004C44.9 29.7004 40.6 25.4004 35.2 25.4004C29.8 25.4004 25.5 29.7004 25.5 35.1004C25.5 37.0004 26.1 38.9004 27.1 40.4004L15.4 52.0004C13.9 51.0004 12.1 50.4004 10.1 50.4004C4.80002 50.4004 0.400024 54.7004 0.400024 60.1004C0.400024 65.4004 4.70003 69.8004 10.1 69.8004C12 69.8004 13.9 69.2004 15.4 68.2004L27 79.8004C26 81.3004 25.4 83.1004 25.4 85.1004C25.4 90.4004 29.7 94.8004 35.1 94.8004C40.5 94.8004 44.8 90.5004 44.8 85.1004C44.8 79.8004 40.5 75.5004 35.2 75.5004Z"  />
    <path fill={color} d="M85.2 25.5004C83.3 25.5004 81.4 26.1004 79.9 27.1004L68.3 15.4004C69.3 13.9004 69.9 12.1004 69.9 10.1004C69.9 4.80039 65.6 0.400391 60.2 0.400391C54.9 0.400391 50.5 4.70039 50.5 10.1004C50.5 15.5004 54.8 19.8004 60.2 19.8004C62.1 19.8004 64 19.2004 65.5 18.2004L77.1 29.8004C76.1 31.3004 75.5 33.1004 75.5 35.1004C75.5 37.0004 76.1 38.9004 77.1 40.4004L65.4 52.0004C63.9 51.0004 62.1 50.4004 60.1 50.4004C54.8 50.4004 50.4 54.7004 50.4 60.1004C50.4 65.4004 54.7 69.8004 60.1 69.8004C65.4 69.8004 69.8 65.5004 69.8 60.1004C69.8 58.2004 69.2 56.3004 68.2 54.8004L79.8 43.2004C81.3 44.2004 83.1 44.8004 85.1 44.8004C90.4 44.8004 94.8 40.5004 94.8 35.1004C94.8 29.7004 90.5 25.5004 85.2 25.5004Z" />
  </svg>







);
